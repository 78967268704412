/**
 * Validator  - validates postal code (zip) must relates to existing country zip code mask
 *
 * @param {string} value - string to validate
 *
 * @param {string} country - current country
 *
 * @returns {boolean}
 */

import * as R from 'ramda';

const ZIP_VALIDATION_PATTERNS = {
  US: /^\d{5}$|^\d{5}-\d{4}$/,
  CA: /^[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]$/,
  IL: /^\d{7}$|^\d{7}/,
};

// eslint-disable-next-line
export const zipCodeValidator = (value, country) => {
  const zipCodePattern = R.prop(country, ZIP_VALIDATION_PATTERNS);

  if (typeof value !== 'string') {
    return false;
  }

  return zipCodePattern.test(value);
};
