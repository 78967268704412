export const ORDER_NOT_COPIED = 'ORDER_NOT_COPIED';
export const PAYMENT_CREATED = 'PAYMENT_CREATED';
export const PAYMENT_UPDATED = 'PAYMENT_UPDATED';
export const PAYMENT_DELETED = 'PAYMENT_DELETED';
export const PAYMENT_NOT_DELETED = 'PAYMENT_NOT_DELETED';
export const PAYMENT_NOT_CREATED = 'PAYMENT_NOT_CREATED';
export const PAYMENT_NOT_UPDATED = 'PAYMENT_NOT_UPDATED';
export const FEE_CREATED = 'FEE_CREATED';
export const FEE_NOT_CREATED = 'FEE_NOT_CREATED';
export const FEE_DELETED = 'FEE_DELETED';
export const FEE_NOT_DELETED = 'FEE_NOT_DELETED';
export const CREDIT_CREATED = 'CREDIT_CREATED';
export const CREDIT_NOT_CREATED = 'CREDIT_NOT_CREATED';
export const ERROR_MESSAGE = 'ERROR_MESSAGE';
export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';
export const MANUAL_ENTRY_DELETED = 'MANUAL_ENTRY_DELETED';
export const MANUAL_ENTRY_NOT_DELETED = 'MANUAL_ENTRY_NOT_DELETED';
export const CONTACT_DELETED = 'CONTACT_DELETED';
export const CONTACT_NOT_DELETED = 'CONTACT_NOT_DELETED';
export const INVOICES_CREATED = 'INVOICES_CREATED';
export const INVOICES_REGENERATED = 'INVOICES_REGENERATED';
export const INVOICES_START_SEND = 'INVOICES_START_SEND';
export const DEBIT_ADJUSTMENT_NOT_CREATED = 'DEBIT_ADJUSTMENT_NOT_CREATED';
export const DEBIT_ADJUSTMENT = 'DEBIT_ADJUSTMENT';

export const WORKFLOW_CREATED = 'WORKFLOW_CREATED';
export const WORKFLOW_UPDATED = 'WORKFLOW_UPDATED';
export const WORKFLOW_ACTIVATED = 'WORKFLOW_ACTIVATED';
export const QUOTE_PROPOSAL_REPORT_PDF_SUCCEED = 'QUOTE_PROPOSAL_REPORT_PDF_SUCCEED';
export const QUOTE_PROPOSAL_REPORT_PDF_ERROR = 'QUOTE_PROPOSAL_REPORT_PDF_ERROR';

export const REFUND_CREATED = 'REFUND_CREATED';
export const REFUND_ERROR = 'REFUND_ERROR';
export const REFUND_DELETED_SUCCESS = 'REFUND_DELETED_SUCCESS';
export const REFUND_DELETED_ERROR = 'REFUND_DELETED_ERROR';
export const BAD_DEBT_CREATED = 'BAD_DEBT_CREATED';
export const BAD_DEBT_ERROR = 'BAD_DEBT_ERROR';

export const notificationMessages = {
  [ORDER_NOT_COPIED]: {
    message: 'Something wrong.',
    type: 'warning',
    title: 'Order Not Copied',
  },
  [PAYMENT_CREATED]: {
    message: 'Payment has been successfully created.',
    type: 'success',
    title: 'Payment Created',
  },
  [PAYMENT_UPDATED]: {
    message: 'Payment has been successfully updated.',
    type: 'success',
    title: 'Payment Updated',
  },
  [PAYMENT_DELETED]: {
    message: 'Payment has been successfully deleted.',
    type: 'success',
    title: 'Payment Deleted',
  },
  [CONTACT_DELETED]: {
    message: 'Contact has been successfully deleted.',
    type: 'success',
    title: 'Contact Deleted',
  },
  [CONTACT_NOT_DELETED]: {
    message: 'Something wrong.',
    type: 'warning',
    title: 'Contact Not Deleted',
  },
  [PAYMENT_NOT_DELETED]: {
    message: 'Something wrong.',
    type: 'warning',
    title: 'Payment Not Deleted',
  },
  [PAYMENT_NOT_CREATED]: {
    message: 'Some required fields or actions are missing.',
    type: 'warning',
    title: 'Payment Didn\'t Create',
  },
  [PAYMENT_NOT_UPDATED]: {
    message: 'Something wrong.',
    type: 'warning',
    title: 'Payment Didn\'t Update',
  },
  [FEE_CREATED]: {
    message: 'Fee has been successfully created.',
    type: 'success',
    title: 'Fee Created',
  },
  [FEE_NOT_CREATED]: {
    message: 'Something wrong.',
    type: 'warning',
    title: 'Fee Not Created',
  },
  [FEE_DELETED]: {
    message: 'Fee has been successfully deleted.',
    type: 'success',
    title: 'Fee Deleted',
  },
  [FEE_NOT_DELETED]: {
    message: 'Something wrong.',
    type: 'warning',
    title: 'Fee Not Deleted',
  },
  [CREDIT_CREATED]: {
    message: 'Fee has been successfully created.',
    type: 'success',
    title: 'Credit Created',
  },
  [CREDIT_NOT_CREATED]: {
    message: 'Something wrong.',
    type: 'warning',
    title: 'Credit Not Created',
  },
  [DEBIT_ADJUSTMENT_NOT_CREATED]: {
    message: 'Something wrong.',
    type: 'warning',
    title: 'Debit Adjustment Not Created',
  },
  [MANUAL_ENTRY_DELETED]: {
    message: 'Manual Entry has been successfully deleted.',
    type: 'success',
    title: 'Manual Entry Deleted',
  },
  [MANUAL_ENTRY_NOT_DELETED]: {
    message: 'Something wrong.',
    type: 'warning',
    title: 'Manual Entry Not Deleted',
  },
  [WORKFLOW_CREATED]: {
    message: 'Workflow has been successfully created.',
    type: 'success',
    title: 'Workflow Created',
  },
  [WORKFLOW_UPDATED]: {
    message: 'Workflow has been successfully updated.',
    type: 'success',
    title: 'Workflow Updated',
  },
  [WORKFLOW_ACTIVATED]: {
    message: 'Workflow has been successfully activated.',
    type: 'success',
    title: 'Workflow Activated',
  },
  [QUOTE_PROPOSAL_REPORT_PDF_SUCCEED]: {
    message: 'PDF has been successfully generated.',
    type: 'success',
    title: 'PDF successfully generated',
  },
  [QUOTE_PROPOSAL_REPORT_PDF_ERROR]: {
    message: 'Something wrong.',
    type: 'warning',
    title: 'PDF was not generated',
  },
  [ERROR_MESSAGE]: {
    message: 'the action was not successful',
    title: 'Something wrong.',
    type: 'warning',
  },
  [SUCCESS_MESSAGE]: {
    message: 'the action was successful',
    title: 'Successful.',
    type: 'success',
  },
  [INVOICES_CREATED]: {
    message: 'Invoices are successfully generated.',
    title: 'Success!',
    type: 'success',
  },
  [INVOICES_REGENERATED]: {
    message: 'Regeneration of invoices is in progress.',
    title: 'Success!',
    type: 'success',
  },
  [INVOICES_START_SEND]: {
    message: 'Sending of invoices is in progress.',
    title: 'Success!',
    type: 'success',
  },
  [REFUND_CREATED]: {
    message: 'Organization refund successfully created',
    type: 'success',
    title: 'Refund successfully created',
  },
  [REFUND_ERROR]: {
    message: 'Something went wrong, please try again.',
    type: 'warning',
    title: 'Refund creation',
  },
  [REFUND_DELETED_SUCCESS]: {
    message: 'Customer refund debit ledger entry successfully deleted',
    type: 'success',
    title: 'Customer refund successfully deleted',
  },
  [REFUND_DELETED_ERROR]: {
    message: 'Something went wrong, please try again',
    type: 'success',
    title: 'Customer refund was not deleted',
  },
  [DEBIT_ADJUSTMENT]: {
    message: 'Debit adjustment was successfully created.',
    type: 'success',
    title: 'Debit Adjustment Created',
  },
  [BAD_DEBT_CREATED]: {
    message: 'Bad debt successfully created',
    type: 'success',
    title: 'Bad debt created',
  },
  [BAD_DEBT_ERROR]: {
    message: 'Something went wrong, please try again.',
    type: 'warning',
    title: 'Bad debt creation',
  },
};
