const abbreviations = ['UID', 'SKU', 'QC'];

/**
 * This is a helper for change first char of string to Upper Case
 *
 * somethingString --> SomethingString
 *
 * @param str
 * @returns {string}
 */
export const capitalize = (str) => {
  if (typeof str !== 'string') return '';
  return str
    .charAt(0)
    .toUpperCase() + str.slice(1);
};

/**
 * This is a helper for change first char of string to Lower Case
 *
 * SomethingString --> somethingString
 *
 * @param str
 * @returns {string}
 */
export const lowerlize = (str) => {
  if (typeof str !== 'string') return '';
  return str
    .charAt(0)
    .toLowerCase() + str.slice(1);
};

/**
 * This is a helper for change notation of string from snake_case to Title String
 *
 * something_string --> Something String
 *
 * @param str
 * @param separator
 * @returns {string}
 */
export const snakeCaseToTitle = (str, separator = ' ', reverse = false) => {
  if (typeof str !== 'string') return '';

  const result = str
    .split('_')
    .map((title) => (abbreviations.includes(title) ? title : capitalize(title.toLowerCase())));

  return !reverse ? result.join(separator) : result.reverse().join(separator);
};

/**
 * This is a helper for change notation of string from snake_case to kebab-case
 *
 * something_string --> something-string
 *
 * @param str
 * @param separator
 * @returns {string}
 */
export const snakeCaseToKebab = (str) => {
  if (typeof str !== 'string') return '';
  return str
    .split('_')
    .map((title) => title.toLowerCase())
    .join('-');
};

/**
 * This is a helper for change notation of string from Title String to snake_string
 *
 * Something String --> something_string
 *
 * @param str
 * @param separator
 * @returns {string}
 */

export const titleToSnake = (str) => {
  if (typeof str !== 'string') return '';
  return str
    .split(' ')
    .map((title) => title.toUpperCase())
    .join('_');
};

/**
 * This is a helper for change notation of string from kebab-case to Title String
 *
 * something-string --> Something String
 *
 * @param str
 * @param separator
 * @returns {string}
 */
export const kebabCaseToTitle = (str) => {
  if (typeof str !== 'string') return '';
  return str
    .split('-')
    .map((title) => title.toLowerCase())
    .map((i) => capitalize(i))
    .join(' ');
};

/**
 * This is a helper for change notation of string from Title String to kebab-case
 *
 *  Something String --> something-string
 *
 * @param str
 * @param separator
 * @returns {string}
 */
export const titleToKebabCase = (str) => {
  if (typeof str !== 'string') return '';
  return str
    .split(' ')
    .map((title) => title.toLowerCase())
    .join('-');
};

/**
 * This is a helper for change notation of string from Title String to camelCase
 *
 *  Something String --> somethingString
 *
 * @param str
 * @param separator
 * @returns {string}
 */
export const titleToCamelCase = (str) => {
  if (typeof str !== 'string') return '';
  const newStr = str
    .split(' ')
    .map((word) => capitalize(word.toLowerCase()))
    .join('');
  return lowerlize(newStr);
};

/**
 * This is a helper for change notation of string from camelCase to Title String
 *
 *  somethingString --> Something String
 *
 * @param str
 * @returns {string}
 */
export const camelCaseToTitle = (str) => {
  if (typeof str !== 'string') return '';

  return capitalize(str).replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1 $2');
};

/**
 * This is a helper for change notation of string from camelCase to Title String
 *
 *  somethingString --> something_string
 *
 * @param str
 * @returns {string}
 */
export const camelCaseToSnake = (str) => {
  if (typeof str !== 'string') return '';

  return (str.charAt(0).toLowerCase() + str.slice(1)).replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1_$2').toLowerCase();
};

export const titleToPascalCase = (str) => {
  if (typeof str !== 'string') return '';
  return str
    .split(' ')
    .map((word) => capitalize(word.toLowerCase()))
    .join('');
};

export const kebabCaseToPascalCase = (str) => {
  if (typeof str !== 'string') return '';
  return str
    .split('-')
    .map((word) => capitalize(word.toLowerCase()))
    .join('');
};

/**
 * This is a helper for change notation of string from kebab-case to snake_case
 *
 * something-string --> something_string
 *
 * @param str
 * @param separator
 * @returns {string}
 */
export const kebabCaseToSnake = (str) => {
  if (typeof str !== 'string') return '';
  return str
    .split('-')
    .join('_');
};

/**
 * This is a helper for deep deleting __typename from object
 * use before mutations
 *
 * @param obj
 * @returns {any}
 */
export const stripTypename = (obj) => JSON.parse(JSON.stringify(obj, (k, v) => (k === '__typename' ? undefined : v)));

/**
 * This is a helper for deep deleting 'new' properties from object
 * use before mutations
 *
 * @param obj
 * @returns {any}
 */
export const stripNewProperty = (obj) => JSON.parse(JSON.stringify(obj, (k, v) => (k === 'new' ? undefined : v)));

/**
 * This is a helper for extracting a number from a string
 * need for sorting list
 *
 * @param str
 * @returns {number}
 */
export const extractNumber = (str) => {
  if (!str || typeof str !== 'string') return 0;
  return parseInt(str.match(/\d/g).join(''), 10);
};

export const valueToLocale = (obj, values) => {
  let refactoredObj = obj;
  values.forEach((value) => {
    refactoredObj = JSON.parse(JSON.stringify(refactoredObj, (k, v) => (k === value ? { value: v } : v)));
  });
  return refactoredObj;
};

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

// eslint-disable-next-line
export const escapeQueryStr = (str) => str.replace(/&{2}|\|{2}|[.*+\-\/"~:?!^${}()[\]\\]/g, '\\$&');

/**
 * The function roundToNearestTen takes a number num as an argument.
 * The number is first divided by 10, then rounded using Math.round().
 * Finally, the result is multiplied by 10 to get the number rounded to the nearest ten.
 * This will give you the desired rounding behavior:

 * Numbers 20, 21-24, 25, 26-29 will round to the nearest 10 as you've described.
*/

function doesNotEndWithZeroOrFive(number) {
  const lastDigit = number % 10;
  return lastDigit !== 0 && lastDigit !== 5;
}

export const roundToNearestTen = (num) => {
  if (num === 0) {
    return '00';
  }

  return doesNotEndWithZeroOrFive(num) ? `${Math.round(Number(num) / 10) * 10}` : `${num}`;
};
