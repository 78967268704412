import Unistring from 'unistring';
import * as R from 'ramda';
import { ALLOWED_TYPES_OF_WORDS } from './constants';

/**
 * Make search requaest string for serach by all words in string
 *
 * @param {string} searchString - String to convert
 *
 * @returns {string}
 */
const makeSearchQuery = R.pipe(
  // split string according to Unicode standard annex UAX#29 word boundaries
  // see http://unicode.org/reports/tr29/#Word_Boundaries
  Unistring.getWords,

  // leave only alphanumeric and numeric tokens
  // (mimicking behavior of the Lucene StandardTokenizer)
  // see https://lucene.apache.org/solr/guide/6_6/tokenizers.html#Tokenizers-StandardTokenizer
  R.filter(
    R.pipe(
      R.propOr('', 'type'),
      // eslint-disable-next-line no-underscore-dangle
      R.contains(R.__, ALLOWED_TYPES_OF_WORDS),
    ),
  ),
  R.map(R.prop('text')),

  // add wildcard at the end of every token longer than two characters
  // R.map((token) => token.length > 2 ? token + '*' : token),
  R.map((token) => `${token}*`),

  R.join(' '),
);

export default makeSearchQuery;
