/**
 *  Util: Allows to stop poll on listing page for debug purposes
 *
 * @return {Number} pollInterval in miliseconds
 */
// eslint-disable-next-line

const getPollInterval = () => (localStorage.getItem('viax-debug') ? 10000000 : 1000);

export default getPollInterval;
