// Elements types
const DISCOUNT = 'DISCOUNT';
const PRICE = 'PRICE';
const FREIGHT = 'FREIGHT';
const TAX = 'TAX';
const SUBTOTAL = 'SUBTOTAL';
const PRODUCT_MODEL_PRICING = 'PRODUCT_MODEL_PRICING';
// Values types
const AMOUNT = 'AMOUNT';
const PERCENTAGE = 'PERCENTAGE';
// Strategy types
const FIRST = 'FIRST';
const HIGHEST = 'HIGHEST';
const LAST = 'LAST';
const LOWEST = 'LOWEST';
// Context attributes
const CONFIGURATION_DATE = 'CONFIGURATION_DATE';
const PRICING_DATE = 'PRICING_DATE';
const PRICING_MODEL_UID = 'PRICING_MODEL_UID';
const PRODUCT_CONFIGURATION_UID = 'PRODUCT_CONFIGURATION_UID';
const PRODUCT_QTY = 'PRODUCT_QTY';
const PRODUCT_UID = 'PRODUCT_UID';
const PRODUCT_SKU = 'PRODUCT_SKU';
const ENTRY_PROMO_CODE = 'ENTRY_PROMO_CODE';
const SALES_DATE = 'SALES_DATE';
const SHIP_TO_ORG_CODE = 'SHIP_TO_ORG_CODE';
const SHIP_TO_ORG_UID = 'SHIP_TO_ORG_UID';
const SHIP_TO_POSTAL_CODE = 'SHIP_TO_POSTAL_CODE';
const INCOTERMS = 'INCOTERMS';
const PRODUCT_GROUPS_UID = 'PRODUCT_GROUPS_UID';
const SOLD_TO_ORG_CODE = 'SOLD_TO_ORG_CODE';
const SOLD_TO_ORG_UID = 'SOLD_TO_ORG_UID';
const SOLD_TO_ORG_ACCOUNT_NUMBER = 'SOLD_TO_ORG_ACCOUNT_NUMBER';
const SHIP_TO_ORG_ACCOUNT_NUMBER = 'SHIP_TO_ORG_ACCOUNT_NUMBER';
const SALES_CHANNEL = 'SALES_CHANNEL';

const defaultContext = [
  ENTRY_PROMO_CODE,
  PRODUCT_SKU,
  PRODUCT_GROUPS_UID,
  SOLD_TO_ORG_CODE,
  SOLD_TO_ORG_ACCOUNT_NUMBER,
  SHIP_TO_ORG_CODE,
  SHIP_TO_ORG_ACCOUNT_NUMBER,
  SHIP_TO_POSTAL_CODE,
  INCOTERMS,
  SALES_CHANNEL,
];

const elementsTypes = [
  {
    label: 'Discount',
    value: DISCOUNT,
  },
  {
    label: 'Price',
    value: PRICE,
  },
  {
    label: 'Freight',
    value: FREIGHT,
  },
  {
    label: 'Tax',
    value: TAX,
  },
  {
    label: 'Subtotal',
    value: SUBTOTAL,
  },
  {
    label: 'Product Model Pricing',
    value: PRODUCT_MODEL_PRICING,
  },
];

const valueTypes = [
  {
    label: 'Amount',
    value: AMOUNT,
  }, {
    label: 'Percentage',
    value: PERCENTAGE,
  },
];

const strategyTypes = [
  {
    label: 'First',
    value: FIRST,
  },
  {
    label: 'Highest',
    value: HIGHEST,
  },
  {
    label: 'Last',
    value: LAST,
  },
  {
    label: 'Lowest',
    value: LOWEST,
  },
];

const CONTEXT_ENTITY_TYPES = {
  PRODUCT_CONFIGURATION_UID: {
    entityName: 'ProductConfiguration',
    component: 'XSearchConfigurationModels',
  },
  PRODUCT_UID: {
    entityName: 'Product',
    component: 'XSearchProducts',
  },
  PRICING_MODEL_UID: {
    entityName: 'PricingModel',
    component: 'XSearchPricingModels',
  },
};

export {
  elementsTypes,
  valueTypes,
  strategyTypes,
  defaultContext,
  CONTEXT_ENTITY_TYPES,
  DISCOUNT,
  PRICE,
  FREIGHT,
  TAX,
  SUBTOTAL,
  PRODUCT_MODEL_PRICING,
  AMOUNT,
  PERCENTAGE,
  FIRST,
  HIGHEST,
  LAST,
  LOWEST,
  CONFIGURATION_DATE,
  PRICING_DATE,
  PRICING_MODEL_UID,
  PRODUCT_CONFIGURATION_UID,
  PRODUCT_QTY,
  PRODUCT_UID,
  PRODUCT_SKU,
  SALES_DATE,
  SHIP_TO_ORG_CODE,
  SHIP_TO_ORG_UID,
  SHIP_TO_POSTAL_CODE,
  SOLD_TO_ORG_CODE,
  SOLD_TO_ORG_UID,
};
