import lstore from '@/utils/localStorage';
import store from '@/store';

const migration = () => {
  lstore.remove('history');
  store.dispatch('history/loadHistory');
  console.log('0.13.0 localstorage migration was executed');
};

export default migration;
