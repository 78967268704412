export const SERVICE_CONTRACT = 'ServiceContract';
export const SALES_QUOTE = 'SalesQuote';
export const SALESFORCE_SALES_ORDER = 'SalesforceSalesOrder';

/**
 * for enum OrderOrganizationSectionType
 * @type {string}
 */
export const SALES_TEAM = 'SALES_TEAM';
export const CUSTOMER = 'CUSTOMER';

/**
 * Array available types for create order
 *
 * @type {string[]}
 */
export const ORDER_TYPES_AVAILABLE = [
  'SERVICE_CONTRACT',
  'SALES_QUOTE',
];

/** default order type for tests
 *
 * @type {string}
 */
export const DEFAULT_ORDER_TYPE = 'SERVICE_CONTRACT';

/**
 * order status by default
 *
 * @type {{enable: boolean, action: string, title: string, value: string}}
 */
export const DEFAULT_ORDER_STATUS = {
  title: 'Initial Draft',
  action: 'initial-draft',
  enable: true,
  value: 'INITIAL_DRAFT',
};

export const ORDER_TYPES = {
  'sales-quote': 'sales-quote',
  'service-contract': 'service-contract',
};

export const INITIAL_DRAFT = 'INITIAL_DRAFT';
export const ENGINEERING = 'ENGINEERING';
export const INSTALLATION = 'INSTALLATION';
export const FINAL_APPROVAL = 'FINAL_APPROVAL';
export const ACTIVE = 'ACTIVE';
export const CLOSED = 'CLOSED';
export const CLOSING = 'CLOSING';

export const ORDER_STATUSES = {
  INITIAL_DRAFT,
  ENGINEERING,
  INSTALLATION,
  FINAL_APPROVAL,
  ACTIVE,
  CLOSED,
};

export const COMMISSIONS_TYPES_LABELS = {
  PRODUCT_BASED: 'Product-based',
  FIXED: 'Fixed',
};

/**
 * function for pricingModel mapping
 *
 * @type {Object[]}
 */
export const pricingModelMap = (item, currentValue) => ({
  ...item,
  selected: item.uid === currentValue,
  name: item.name,
  title: item.name,
  subtitle: item.description,
  value: item.uid,
});

export const SOLD_TO_CUSTOMER = 'SOLD_TO_CUSTOMER';
export const CUSTOMER_ORG_TYPE = 'CUSTOMER';
export const SERVICE_LOCATION = 'SERVICE_LOCATION';
export const DEFAULT = 'DEFAULT';

export const DEFAULT_CONTRACT_TERM = {
  code: 'MONTHS_36',
  name: '36 months',
  value: 36,
};
export const DEFAULT_BILLING_PLAN = 'MONTHLY_ADVANCE_1';
export const DEFAULT_PAYMENT_TERM = 'NET_15_DAYS';
export const ACTIVE_STATUS = 'ACTIVE';

export const CONSOLIDATION_TYPES_LABELS = {
  INVOICE_DATE: 'Invoice date',
  PURCHASE_NUMBER: 'Purchase number',
  SOLD_TO_ORGANIZATION: 'Sold-to organization',
};

export const CLOSE_CONTRACT_TYPES = {
  CHARGE_ETF: 'CHARGE_ETF',
  PROVIDE_CREDIT: 'PROVIDE_CREDIT',
  DO_NOTHING: 'DO_NOTHING',
};
