import * as R from 'ramda';
import { normalizePhone } from './normalizePhone';

/**
 *  Helper: Map propery of one object to another
 *
 * @param {array} fromPath - path of property in sourceObject
 * @param {object} sourceObj
 * @param {array} toPath
 * @param {function} fn - function wich transform
 *
 * @return {function}
 */
// eslint-disable-next-line
export const mapProp = (fromPath, sourceObj, toPath, fn) => (destObj) => {
  let res = { ...destObj };

  if (R.hasPath(fromPath, sourceObj) && R.path(fromPath, sourceObj) !== '') {
    const value = R.path(fromPath, sourceObj);
    const transformedValue = fn(value);
    res = R.assocPath(toPath, transformedValue, destObj);
  }

  return res;
};

/**
 *  Helper: convert to null if string length is zero
 *
 * @param {string} val

 * @return {string || null}
 */
export const emptyToNull = (val) => ((val && val.length) ? val : null);

/**
 *  Helper: convert null or undefined to empty array
 *
 * @param {array || null || undefined} val

 * @return {array}
 */
export const nullToEmptyArray = (val) => (val || []);

/**
 *  Helper: convert to emty string if value is null
 *
 * @param {*} val

 * @return {string}
 */
export const phoneToOnlyDigitsFormat = (val) => {
  if (val && val.length) {
    return R.replace(/\(|\)|-|\s/g, '', val);
  }
  return null;
};

// TODO Add description
export const emptyToFalse = (val) => (!!val);

// TODO Add description
export const nullToEmpty = (val) => ((val) || '');

/**
 *  Helper: Normalize phone number for UI or return emty string if it null
 *
 * @param {*} val

 * @return {string}
 */
export const preparePhone = (val) => {
  if (val && val.length) {
    return normalizePhone(val);
  }
  return '';
};

/**
 *  Helper: Normalize zip for UI from zipCode and zipPlus4
 *
 * @param {object} model - model which contains zipCode and zipPlus4 info
 *
 * @return {object} - conteins combined zip property
 */
// eslint-disable-next-line
export const zipToView = model => (destObj) => {
  let res = { ...destObj };

  const zipCode = R.path(['zipCode'], model);
  const zipPlus4 = R.path(['zipPlus4'], model);

  if (!zipPlus4 && zipCode) {
    res = R.assocPath(['zip'], zipCode, res);
  }

  if (zipPlus4) {
    res = R.assocPath(['zip'], `${zipCode}-${zipPlus4}`, res);
  }

  if (!zipCode) {
    res = R.assocPath(['zip'], '', res);
  }

  return res;
};

// TODO Add descriotion
// ADd tests
export const addZip = (model, upsert = true) => (destObj) => {
  let res = { ...destObj };

  const zip = R.propOr('', 'zip')(model);

  if (zip.length >= 5 && zip.length < 10) {
    res = R.assocPath(['address', 'address', 'zipCode'], zip, res);
    res = R.assocPath(['address', 'address', 'zipPlus4'], null, res);
  }

  if (zip.length === 10) {
    res = R.assocPath(['address', 'address', 'zipCode'], zip.substring(0, 5), res);
    res = R.assocPath(['address', 'address', 'zipPlus4'], zip.substring(6, 10), res);
  }

  if (zip.length === 0 && upsert) {
    res = R.assocPath(['address', 'address', 'zipCode'], null, res);
    res = R.assocPath(['address', 'address', 'zipPlus4'], null, res);
  }

  return res;
};
