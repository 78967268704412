/**
 * @module store/actions
 *
 * @version 1.1.0
 * @copyright viax.io 2019
 */

/**
 * Action: App init
 *
 * @since Version 1.0.0
 */
const appInit = ({ dispatch }) => {
  dispatch('history/loadHistory');
};

export default {
  appInit,
};
