/**
 * Convert filter to string
 *
 * @param {object} - filter
 *
 * @returns {string}
 */
const convertFilterToString = (filter) => {
  let filtersSctring = '';

  if (filter.type === 'enum' || filter.value === null) {
    filtersSctring = `${filter.field} ${filter.operator} ${filter.value}`;
  }

  switch (typeof filter.value) {
    case 'boolean':
      // eslint-disable-next-line no-useless-escape
      filtersSctring = `${filter.field} ${filter.operator} ${filter.value}`;
      break;
    case 'string':
      if (filter.type === 'enum') {
        filtersSctring = `${filter.field} ${filter.operator} ${filter.value}`;
      } else {
        // eslint-disable-next-line no-useless-escape
        filtersSctring = `${filter.field} ${filter.operator} \"${filter.value}\"`;
      }
      break;
    case 'object':
      if (filter.type === 'enum') {
        filtersSctring = filter.value.length
          ? `${filter.field} ${filter.operator} (${filter.value.join(', ')})`
          : '';
      } else if (filter.value === null) {
        filtersSctring = `${filter.field} ${filter.operator} ${filter.value}`;
      } else {
        // eslint-disable-next-line no-useless-escape
        const values = (Array.isArray(filter.value)) ? filter.value.map((item) => `\"${item}\"`).join(',') : '';
        filtersSctring = filter.value.length
          ? `${filter.field} ${filter.operator} (${values})`
          : '';
      }
      break;
    default:
      break;
  }

  return filtersSctring;
};

export default convertFilterToString;
