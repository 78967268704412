/**
 * This is a helper for format price element value depending on conditions
 *
 * 100000000 --> 100,000,000.00
 * 100000000 --> (100,000,000.00)
 *
 * @param priceElement
 * @param showZeroResult
 * @returns {string}
 */

import {
  PERCENTAGE,
  DISCOUNT,
} from '@/utils/pricingConstants';
import addCommasAndDotToNumber from '@/utils/addCommasAndDotToNumber';

const formattedPriceElementValue = (priceElement, showZeroResult = false) => {
  let formattedElement = '';

  if (priceElement.valueType === PERCENTAGE) {
    if (!priceElement.percentageAmount) formattedElement = showZeroResult ? '0.00' : '';
    else formattedElement = addCommasAndDotToNumber(priceElement.percentageAmount);
  }

  if (priceElement.valueType !== PERCENTAGE) {
    if (!priceElement.decimalValue) formattedElement = showZeroResult ? '0.00' : '';
    else formattedElement = addCommasAndDotToNumber(priceElement.decimalValue);
  }

  if (priceElement.elementType === DISCOUNT && formattedElement) formattedElement = `(${formattedElement})`;

  return formattedElement;
};

export default formattedPriceElementValue;
