import * as R from 'ramda';
import convertFilterToString from './convertFilterToString';

/**
 * Convert array of filters to string
 *
 * @param {array} - filters
 *
 * @returns {string}
 */
const convertFiltersToString = (filters) => {
  let filtersString = '';

  if (!R.isEmpty(filters)) {
    filtersString = R.pipe(
      R.map(convertFilterToString),
      R.join(' AND '),
    )(filters);
  }

  return filtersString;
};

export default convertFiltersToString;
