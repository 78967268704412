/**
 * @module store/history/mutations
 *
 * @version 1.0.0
 * @copyright viax.io 2019
 */
import * as R from 'ramda';
import { DEFAULT_HISTORY_LENGTH } from '@/config';
import * as types from './mutation-types';

export default {
  /**
   * Mutation: Add item to history
   *
   * @param {string} historyId - unic id of history
   * @param {string} item - item to add to hisory
   *
   * @since Version 1.0.0
   */
  [types.ADD_ITEM](state, { historyId, item }) {
    // Adding item to history
    if (state.history[historyId] && state.history[historyId].items) {
      let items = [item, ...state.history[historyId].items];
      items = new Set(items);
      items = [...items].slice(0, state.history[historyId].length);
      state.history[historyId].items = items;
    } else {
      state.history[historyId] = {
        length: DEFAULT_HISTORY_LENGTH,
        items: [item],
      };
    }
  },

  /**
   * Mutation: Set length for history
   *
   * @param {string} historyId - unic id of history
   * @param {number} length - Length of history
   *
   * @since Version 1.0.0
   */
  [types.SET_LENGTH](state, { historyId, length }) {
    if (state.history[historyId]) {
      state.history[historyId].length = length;
      state.history[historyId].items = state.history[historyId].items.slice(0, length);
    } else {
      state.history[historyId] = {
        length,
        items: [],
      };
    }
  },

  /**
   * Mutation: Set history
   *
   * @param {object} history - history
   *
   * @since Version 1.0.0
   */
  [types.SET_HISTORY](state, history) {
    state.history = history;
  },

  /**
   * Mutation: Add path to navigation history
   *
   * @param {string} historyId - unic id of history
   * @param {string} item - item to add to hisory
   *
   * @since Version 1.0.0
   */
  [types.ADD_PATH](state, { historyId, item }) {
    if (state.navigationHistory[historyId]) {
      let items = [item, ...state.navigationHistory[historyId]];
      items = new Set(items);
      state.navigationHistory[historyId] = [...items];
    } else {
      state.navigationHistory[historyId] = [item];
    }
  },

  /**
   * Mutation: Go back in navigation history
   *
   * @param {string} historyId - unic id of history
   * @param {string} item - item to add to hisory
   *
   * @since Version 1.0.0
   */
  [types.GO_BACK](state, historyId) {
    if (state.navigationHistory[historyId] && state.navigationHistory[historyId].length) {
      state.navigationHistory[historyId] = R.drop(1, state.navigationHistory[historyId]);
    }
  },
};
