/**
 *  Util: checks if debug mode is enabled
 *
 * @return {Boolean} on/off
 */
// eslint-disable-next-line

const isDebugMode = () => (localStorage.getItem('viax-debug'));

export default isDebugMode;
