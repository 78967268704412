import objectOrArrayToArray from './objectOrArrayToArray';
import makeFilterString from './makeFilterString';
import makeOrderArray from './makeOrderArray';

const makeSearchParams = (initialState = {}) => {
  const string = initialState.string || '';
  const fields = objectOrArrayToArray(initialState.fields);
  const filters = objectOrArrayToArray(initialState.filters);
  const defaultOrder = objectOrArrayToArray(initialState.defaultOrder);
  const order = objectOrArrayToArray(initialState.order);

  const searchParams = {
    filter: makeFilterString(string, fields, filters),
  };

  const orderBy = makeOrderArray(string, defaultOrder, order);

  if (orderBy.length) {
    searchParams.orderBy = orderBy;
  }

  return searchParams;
};

export default makeSearchParams;
