import converSearchToString from './converSearchToString';
import convertFiltersToString from './convertFiltersToString';

const makeFilterString = (string, fields, filters) => {
  const search = converSearchToString(string, fields);
  const filter = convertFiltersToString(filters);

  const searchAndFilter = [search, filter]
    .filter((item) => item)
    .map((item) => `(${item})`)
    .join(' AND ');

  return searchAndFilter;
};

export default makeFilterString;
