import * as R from 'ramda';

/**
 * Helper: Leave only digits in string
 *
 * @param {string} str - input string
 *
 * @returns {sring}
 */
// eslint-disable-next-line
export const leaveOnlyDigits = R.replace(/[^0-9]/g, '');
