/**
 *  @module util/migrationsEngine - Helpers for working with migrations
 *
 * @version 1.0.0
 * @copyright viax.io 2019
 */

import lstore from '@/utils/localStorage';

const getExecutedMigrations = (clientVersion, migrations) => {
  const migrationsToexecute = [];
  const items = (migrations) || require.context('@/migrations', false, /\/*\.js$/).keys();

  items.forEach((key) => {
    const fileName = key.match(/[0-9]*\.[0-9]*\.[0-9]*/g);
    const a = fileName[0].split('.');
    const b = clientVersion.split('.');
    if (a[0] * 1 > b[0] * 1 || a[1] * 1 > b[1] * 1 || a[2] * 1 > b[2] * 1) {
      migrationsToexecute.push(fileName[0]);
    }
  });

  return migrationsToexecute;
};

const executeNeededMigrations = (migrations) => {
  migrations.forEach((migration) => {
    const migrationFunction = import(`@/migrations/${migration}`);
    if (migrationFunction) {
      migrationFunction.then((res) => {
        res.default();
      });
    }
  });
};

const runMigrations = (appVersion) => {
  const clientVersion = lstore.getValue('app-version') || '0.0.0';

  if (appVersion !== clientVersion) {
    executeNeededMigrations(getExecutedMigrations(clientVersion));
    lstore.setValue('app-version', appVersion);
  }
};

export default {
  runMigrations,
  getExecutedMigrations,
};
