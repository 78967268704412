const makeFilterData = (fields) => fields.map(({
  field, value, nullable, exclude, range, gte, lte,
}) => {
  if (nullable) {
    return {
      bool: {
        must_not: {
          exists: { field },
        },
      },
    };
  }

  if (exclude) {
    return {
      bool: {
        must_not: {
          terms: {
            [field]: Array.isArray(value) ? value : [value],
          },
        },
      },
    };
  }

  if (range) {
    return {
      range: {
        [field]: {
          gte,
          lte,
        },
      },
    };
  }

  return {
    terms: {
      [field]: Array.isArray(value) ? value : [value],
    },
  };
});

export default makeFilterData;
