/**
 * This is a helper to add commas after each 3 digits and toFixed(2) for number
 *
 * 222222222.2 --> 222,222,222.20
 *
 * @param value
 * @returns {string}
 */

const addCommasAndDotToNumber = (value) => String(value.toFixed(2))
  .split('.')
  .map((numberPart) => numberPart.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'))
  .join('.');

export default addCommasAndDotToNumber;
