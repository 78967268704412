/**
 * @module store/history/getters
 *
 * @version 1.0.0
 * @copyright SBC 2019
 */

import * as R from 'ramda';
/**
 * Getter: History by id
 *
 * @param {string} historyId - id of history
 *
 * @returns {array} - array of items in history, first eitem was added last
 *
 * @since Version 1.0.0
 */
const history = (state) => (historyId) => R.pathOr([], ['history', historyId, 'items'], state);

/**
 * Getter: Navigation history
 *
 * @param {string} historyId - id of history
 *
 * @returns {array} - array of items in history, first eitem was added last
 *
 * @since Version 1.0.0
 */
const navigationHistory = (state) => (historyId) => R.pathOr([], ['navigationHistory', historyId], state);

export default {
  history,
  navigationHistory,
};
