/**
 * Helper: Normalize phone number for UI
 *
 * 10 digits phone number will be trnsformed to (###) ###-##-##
 * Other strings will be returnd without changes
 *
 * @package {string} phone - not normalized phone
 *
 * @returns {string} - normalized phone
 *
 * @copyright viax.io 2018
 */

import { leaveOnlyDigits } from './leaveOnlyDigits';

// eslint-disable-next-line
export const normalizePhone = (phone, country = 'US') => {
  const onlyDigits = leaveOnlyDigits(phone);

  if ((country === 'CA' || country === 'US') && onlyDigits.length === 10) {
    return `(${onlyDigits.substring(0, 3)}) ${onlyDigits.substring(3, 6)}-${onlyDigits.substring(6, 10)}`;
  }
  if (country === 'IL') {
    return `(${onlyDigits.substring(0, 1)}) ${onlyDigits.substring(1, 4)}-${onlyDigits.substring(4, 7)}-${onlyDigits.substring(7, 10)}`;
  }

  return phone;
};
