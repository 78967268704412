import splitToWords from './splitToWords';

/**
 * Helper returns the truth if the search string contains at least one token.
 *
 * @param {string} searchString
 *
 * @returns {boolean}
 */
const isStringContainToken = (string) => (splitToWords(string).length > 0);

export default isStringContainToken;
