import * as R from 'ramda';
import isStringContainToken from './isStringContainToken';
import makeSearchQuery from './makeSearchQuery';

/**
 * Helper convert list of search to string
 *
 * @param {object|array} order
 */
const converSearchToString = (searchString, fields) => (isStringContainToken(searchString)
  ? R.pipe(
    R.map((item) => `${item} like "${makeSearchQuery(searchString)}"`),
    R.join(' OR '),
  )(fields)
  : '');

export default converSearchToString;
