/**
 * @module store/hisory/actions
 *
 * @version 1.0.2
 * @copyright viax.io 2019
 */

import lstore from '@/utils/localStorage';
import * as types from './mutation-types';
import { HISTORY_LOCAL_STORAGE_KEY } from './constants';

/**
 * Action: Add item to history
 *
 * @param {string} historyId - unic id of history
 * @param {string} item - item to add to hisory
 *
 * @since Version 1.0.0
 */
const addToHistory = async ({ state, commit }, { historyId, item }) => {
  commit(types.ADD_ITEM, { historyId, item });
  lstore.setValue(HISTORY_LOCAL_STORAGE_KEY, state.history);
};

/**
 * Action: Load history from local storage
 *
 * @since Version 1.0.0
 */
const loadHistory = async ({ state, commit }) => {
  const history = lstore.getValue(HISTORY_LOCAL_STORAGE_KEY, state.history) || {};
  commit(types.SET_HISTORY, history);
};

/**
 * Action: Set history langth
 *
 * @param {string} historyId - unic id of history
 * @param {number} - length - Length of history
 *
 * @since Version 1.0.0
 */
const setHistoryLength = async ({ state, commit }, { historyId, item }) => {
  commit(types.SET_LENGTH, { historyId, item });
  lstore.setValue(HISTORY_LOCAL_STORAGE_KEY, state.history);
};

/**
 * Action: Add path to history
 *
 * @param {string} historyId - unic id of history
 * @param {string} item - item to add to hisory
 *
 * @since Version 1.0.2
 */
const addPathToHistory = async ({ commit }, { historyId, item }) => {
  commit(types.ADD_PATH, { historyId, item });
};

/**
 * Action: Go back path to history
 *
 * @param {string} historyId - unic id of history
 *
 * @since Version 1.0.2
 */
const goBack = async ({ commit }, historyId) => {
  commit(types.GO_BACK, historyId);
};

export default {
  addPathToHistory,
  addToHistory,
  goBack,
  loadHistory,
  setHistoryLength,
};
