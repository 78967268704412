const keysOfKeycloackHash = ['state', 'session_state', 'code'];

const removeKeycloackHash = (hash) => {
  const newHash = hash
    .slice(1)
    .split('&')
    .map((i) => i.split('='))
    .filter((i) => !keysOfKeycloackHash.includes(i[0]))
    .map((i) => i.join('='))
    .join('&');
  return newHash;
};

export default removeKeycloackHash;
export { removeKeycloackHash };
