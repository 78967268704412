<template>
  <div
    class="app"
  >
    <router-view />

    <x-notifications />
  </div>
</template>

<script>
/**
 * Base component for app
 *
 * @version 1.0.0
 */

import { mapActions } from 'vuex';
import { XNotifications } from '@viax/component-library-apx';

import migrationsEngine from '@/utils/migrationsEngine';
import {
  ADMIN_USER_ROLE,
  CUSTOMER_PORTAL,
  PROVISIONING,
  ALLOWED_PROVISIONING_ROUTES,
} from '@/utils';
import getUserRoles from '@/utils/getUserRoles';

export default {
  name: 'app',

  components: {
    XNotifications,
  },

  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      buildTime: process.env.VUE_APP_BUILD_TIME,
      apiUrl: process.env.VUE_APP_GRAPHQL_ENDPOINT,
    };
  },

  created() {
    migrationsEngine.runMigrations(this.version);
    this.appInit();
    this.processRedirect();
  },

  mounted() {
    const { version, buildTime, apiUrl } = this;

    console.log(`%c viax.io App v.${version} from ${buildTime}`, 'color: red;');
    console.log(`%c API URL: ${apiUrl}`, 'color: red;');

    if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
      const args = ['\n %c Made with ❤️ by viax.io %c\n', 'border: 1px solid #000; color: #000; background: #fa6500; padding:5px 0;', 'border: none;'];
      window.console.log.apply(console, args);
    } else if (window.console) {
      window.console.log('Made with love ❤️ viax.io');
    }
  },

  methods: {
    ...mapActions({
      appInit: 'appInit',
    }),
    processRedirect() {
      const roles = getUserRoles(this.$keycloak.tokenParsed);
      if (!roles.includes(ADMIN_USER_ROLE)) {
        if (roles.includes(CUSTOMER_PORTAL)) {
          this.$router.replace({ name: 'payment-portal' });
          return;
        }
        if (roles.includes(PROVISIONING)) {
          if (ALLOWED_PROVISIONING_ROUTES.some(route => this.$router.options.history.state.current.startsWith(route))) return;
          this.$router.replace({ name: 'apxreports' });
        }
      }
    },
  },
};
</script>

<style lang="scss">
// Common styles
@import '@viax/component-library-apx/src/scss/global.scss';

// TODO: Remove after show
*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

html,
body {
  position: relative;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

*:focus {
  outline: none;
}

.app {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
