/**
 * Download file in browser by url
 *
 * @param {string} url
 * @returns {Promise<void>}
 */
const downloadFileByUrl = async (url) => {
  const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default downloadFileByUrl;
