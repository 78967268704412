/**
 *  @module util/loacalStorage - Helpers for working with local storage
 *
 * @version 1.0.0
 * @copyright viax.io 2019
 */

import log from '@/utils/logger';

import { LOCAL_STORAGE_PREFIX } from '@/utils';

/**
 * Функция хелпер, котрая добавляет неймспейс ко всем ключам в локалсторедже
 *
 * @param {string} key - Ключ
 */
const keyWithPrefix = (key) => `${LOCAL_STORAGE_PREFIX}${key}`;

/**
 * Функция сохранения переменной в локал сторедже
 *
 * @param {string} key - Ключ, по которому нужно сохранить данные
 * @param {*} value - Данные, которые нужно сохранить
 *
 * @since Version 1.0.0
 */
const setValue = (key, value) => {
  localStorage.setItem(keyWithPrefix(key), JSON.stringify(value));
};

/**
 * Функция сохранения массива в локал сторедже
 *
 * @param {string} key - Ключ, по которому нужно сохранить данные
 * @param {*} data - Данные, которые нужно сохранить
 *
 * @since Version 1.0.0
 */
const setArray = (key, value) => {
  const array = value === null ? [] : value;
  localStorage.setItem(keyWithPrefix(key), JSON.stringify(array));
};

/**
 * Функция загрузки переменной из локал стореджа
 *
 * @param {string} key - Ключ, по которому нужно сохранить данные
 *
 * @since Version 1.0.0
 */
const getValue = (key) => {
  const fullKey = keyWithPrefix(key);
  const stored = localStorage.getItem(fullKey);
  if (stored !== null) {
    try {
      return JSON.parse(stored);
    } catch (error) {
      log.error(`Error in parsing value from local storage key ${fullKey}: ${error}`);
    }
  }
  return null;
};

/**
 * Функция загрузки массива из локал стореджа
 *
 * @param {string} key - Ключ, по которому нужно сохранить данные
 *
 * @since Version 1.0.0
 */
const getArray = (key) => {
  const fullKey = keyWithPrefix(key);
  const stored = localStorage.getItem(fullKey);
  if (stored !== null) {
    try {
      return JSON.parse(stored);
    } catch (error) {
      log.error(`Error in parsing value from local storage key ${fullKey}: ${error}`);
    }
  }
  return [];
};

/**
 * Функция данных в локал сторедже
 *
 * @param {string} key - Ключ, по которому нужно удалить данные
 *
 * @since Version 1.0.0
 */
const remove = (key) => {
  localStorage.removeItem(keyWithPrefix(key));
};

export default {
  getArray,
  getValue,
  remove,
  setArray,
  setValue,
};
