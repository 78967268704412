import * as R from 'ramda';

const makeQuery = (fields, string, allowSpecialCharacters) => {
  const trimmedString = string.trim();
  let queryString;

  queryString = trimmedString.split(' ').filter((val) => val !== '&').join('* ');

  if (allowSpecialCharacters) {
    queryString = trimmedString.split(' ').join('* ');
  }

  queryString = queryString.replaceAll('–', '-');

  return fields.map((field) => {
    if (R.includes('phoneNumber', field)) {
      return {
        match: {
          [field]: {
            query: string.replace(/[^0-9]+/g, ''),
          },
        },
      };
    }

    return {
      simple_query_string: {
        query: `${queryString}*`,
        fields: [field],
        default_operator: 'and',
      },
    };
  });
};

export default makeQuery;
