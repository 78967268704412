import * as R from 'ramda';

/**
 * Helper: Convert undefined, object or array to array
 *
 * undefined -> []
 * {} -> [{}]
 * [] -> []
 *
 * @param {undefined|object|array}
 *
 * @returns {arrat}
 */
const objectOrArrayToArray = R.pipe(
  (input) => input || [],
  R.ifElse(
    (input) => Array.isArray(input),
    R.identity,
    (input) => [input],
  ),
);

export default objectOrArrayToArray;
