import * as R from 'ramda';

/**
 * This is a helper for update state
 *
 *
 * @param {string} key - path of property in sourceObject
 * @param {object} value
 * @param {object} state
 * @returns {Object}
 */
export const updateState = (key, value, state) => ({ ...state, [key]: { ...state[key], currentValue: value } });

/**
 * This is a helper for get filed value from state
 *
 * @param {string} deepProp
 * @param {object} stateField
 * @returns {Object}
 */

export const getFromState = (stateField, deepProp) => {
  const path = ['currentValue'];
  const defaultPath = ['defaultValue'];
  if (deepProp) {
    path.push(deepProp);
    defaultPath.push(deepProp);
  }
  return R.pathOr(R.pathOr('', defaultPath, stateField), path, stateField);
};

/**
 * This is a helper for check if field is disabled
 *
 * @param {object} stateField
 * @returns {boolean}
 */

export const isDisabled = (fieldState) => !R.contains('WRITE', R.propOr([], 'permissions', fieldState));

/**
 * This is a helper for check if field is visible
 *
 *
 * @param {object} stateField
 * @returns {boolean}
 */

export const isVisible = (fieldState) => R.contains('READ', R.propOr([], 'permissions', fieldState));

/**
 * This is a helper for gettng error from state field
 *
 *
 * @param {object} stateField
 * @returns {boolean}
 */

export const getError = (fieldState) => R.propOr('', 'error', fieldState);
