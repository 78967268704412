import * as R from 'ramda';

/**
 * Gets array of user roles from parsed token of user
 *
 * @package {Object} parsedToken - Parsed token of user given by KeyCloak
 *
 * @returns {Array}
 */
const getUserRoles = (parsedToken) => R.pathOr([], ['realm_access', 'roles'], parsedToken);

export default getUserRoles;
