import store from '@/store';

/**
 * Save id to history
 *
 * @param {string} id
 * @param {string} historyId
 */
export const saveUidToHistory = (uid, historyId) => {
  store.dispatch('history/loadHistory');
  store.dispatch('history/addToHistory', {
    historyId,
    item: uid,
  });
};

/**
 * Save route to history
 */
export const saveRouteToHistory = (route, historyId) => {
  store.dispatch('history/addPathToHistory', {
    historyId,
    item: route,
  });
};
