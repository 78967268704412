/**
 * Helper build by list of not empty array items
 *
 * @param {array} list - List of items
 * @param {string} separator
 *
 * @returns {string}
 *
 * @version 1.0.0
 */

const makeStringFromList = (list, separator) => (list.filter((item) => item).join(separator));

export default makeStringFromList;
