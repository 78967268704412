/**
 * Helper build by Organization Address
 *
 * @param {object} organization - Organization object
 *
 * @returns {string}
 *
 * @version 1.0.0
 */

import * as R from 'ramda';

import makeStringFromList from '@/utils/makeStringFromList';
import { countries } from '@/utils/constants';

const makeAddressLine = (address) => {
  const {
    line1, line2, city, state, zipCode, zipPlus4, country,
  } = address || {};
  const zip = makeStringFromList([zipCode, zipPlus4], '-');
  const stateAndZipAndCountry = makeStringFromList([state, zip, R.pathOr('', [country, 'name'], countries)], ' ');
  return makeStringFromList([line1, line2, city, stateAndZipAndCountry], ', ');
};

export default makeAddressLine;
