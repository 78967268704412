const googleAnalyticsSearchTerm = (searchCategory, searchTerm) => {
  if (process.env.NODE_ENV === 'production' && searchTerm) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'searchEntity',
      searchCategory,
      searchTerm,
    });
  }
};

export default googleAnalyticsSearchTerm;
