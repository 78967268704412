import { createStore as _createStore } from 'vuex';
import modules from './modules';

import actions from './actions';

export default _createStore({
  actions,
  modules,
  strict: process.env.NODE_ENV !== 'production',
});
