/**
 *  @module util/logger - Logging
 *
 * @see See [Example](https://www.sitepoint.com/logging-errors-client-side-apps/)
 *
 * @todo Add storing data to cloud (Sentry+Raven).
 *
 * @version 1.0.0
 * @copyright viax.io 2018
 */

// import { Raven } from 'vue-raven';

class Logger {
  // Log levels as per https://tools.ietf.org/html/rfc5424
  static get ERROR() { return 3; }

  static get WARN() { return 4; }

  static get INFO() { return 6; }

  static get DEBUG() { return 7; }

  constructor(options) {
    if (!options || typeof options !== 'object') {
      throw new Error('options are required, and must be an object');
    }

    this.level = options.level || Logger.DEBUG;
  }
  // eslint-disable-next-line
  log(level, message, tags = {}) {
    /* eslint-disable no-console */
    if (level <= this.level) {
      switch (level) {
        case Logger.ERROR:
          if (process.env.NODE_ENV === 'production') {
            // Raven.captureMessage(message, {
            //   level: 'error',
            //   tags,
            // });
          } else {
            console.error(message);
          }
          break;
        case Logger.WARN:
          if (process.env.NODE_ENV === 'production') {
            // Raven.captureMessage(message, {
            //   level: 'warning', // one of 'info', 'warning', or 'error'
            // });
          } else {
            console.warn(message);
          }
          break;
        case Logger.INFO:
          if (process.env.NODE_ENV === 'production') {
            // Raven.captureMessage(message, {
            //   level: 'info',
            //   tags,
            // });
          } else {
            console.info(message);
          }
          break;
        case Logger.DEBUG:
          console.info(message);
          if (process.env.NODE_ENV === 'production') {
            // Raven.captureMessage(message, {
            //   level: 'info',
            //   tags,
            // });
          } else {
            console.info(message);
          }
          break;
        default:
          break;
      }
    }
    /* eslint-enable */
  }

  error(message, tags = {}) {
    this.log(Logger.ERROR, message, tags);
  }

  warn(message, tags = {}) {
    this.log(Logger.WARN, message, tags);
  }

  info(message, tags = {}) {
    this.log(Logger.INFO, message, tags);
  }

  debug(message, tags = {}) {
    this.log(Logger.DEBUG, message, tags);
  }
}

export default new Logger({
  level: process.env.NODE_ENV === 'production' ? Logger.DEBUG : Logger.DEBUG,
});
